<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <el-page-header @back="back" :content="spuId ? '编辑商品' : '新增商品'"> </el-page-header>
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </div>
    <div class="container">
      <el-form v-model="spu" label-width="120px">
        <el-form-item label="商品名称">
          <div style="display: flex">
            <el-input v-model="spu.name" style="width: 300px"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="商品排序">
          <div style="display: flex">
            <el-input v-model="spu.sort" placeholder="请输入商品排序, 越大越靠前" style="width: 300px"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="商品销量">
          <div style="display: flex">
            <el-input v-model="spu.salesCount" placeholder="请输入商品销量, 仅供前端展示" style="width: 300px"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="查看量">
          <div style="display: flex">
            <el-input v-model="spu.playCount" placeholder="请输入查看量" style="width: 300px"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="购买后展示弹窗">
          <div style="display: flex"><el-switch style="margin-top: 10px" v-model="spu.showPopup"></el-switch></div>
        </el-form-item>
        <el-form-item label="弹窗内容">
          <div v-if="qrImageUrl && qrImageUrl.length" style="display: flex; position: relative">
            <el-image :src="qrImageUrl" fit="contain" :preview-src-list="[qrImageUrl]" style="width: 150px; height: 150px"> </el-image>
            <div class="image-hover">
              <i class="el-icon-delete delete" @click="handleRemoveImage('qrImageUrl')"></i>
            </div>
          </div>
          <el-upload v-else class="uploader" action="cover" :before-upload="uploadQrImage" v-loading="qrImageUrlLoading">
            <i class="el-icon-plus uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="封面图">
          <div v-if="logoUrl.length" style="display: flex; position: relative">
            <el-image :src="logoUrl" fit="contain" :preview-src-list="[logoUrl]" style="width: 150px; height: 150px"> </el-image>
            <div class="image-hover">
              <i class="el-icon-delete delete" @click="handleRemoveImage('cover')"></i>
            </div>
          </div>
          <el-upload v-else class="uploader" action="cover" :before-upload="uploadCover" v-loading="coverUploading">
            <i class="el-icon-plus uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="Banner列表">
          <div style="display: flex; align-items: center; overflow: auto">
            <draggable v-model="bannerImageList">
              <transition-group style="display: flex; flex-wrap: wrap; border: 1px solid red" :animation="180">
                <div v-for="(item, index) in bannerImageList" :key="item.name" style="margin-right: 20px">
                  <div v-if="item.type === 'image'">
                    <el-image :src="item.value" style="width: 150px; height: 150px" fit="contain" :preview-src-list="[item.value]"></el-image>
                    <div>111</div>
                    <div class="image-hover">
                      <i class="el-icon-delete delete" @click="handleRemoveImage('banner', index)"></i>
                    </div>
                  </div>
                  <video v-else controls height="200" width="300" :src="item.value"></video>
                </div>
              </transition-group>
            </draggable>
            <el-upload class="uploader" action="banner" multiple :before-upload="uploadBanner" v-loading="bannerUploading" accept=".jpg,.mp4,.png">
              <i class="el-icon-plus uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="详情图">
          <div style="display: flex; align-items: center; overflow: auto">
            <draggable v-model="detailImageList">
              <div v-for="(item, index) in detailImageList" :key="index" style="width: 150px; height: 150px; margin-right: 20px">
                <el-image :src="item.value" style="width: 150px; height: 150px" fit="contain" :preview-src-list="[item.value]"></el-image>
                <div class="image-hover">
                  <i class="el-icon-delete delete" @click="handleRemoveImage('detail', index)"></i>
                </div>
              </div>
            </draggable>
            <el-upload class="uploader" action="detail" multiple :before-upload="uploadDetail" v-loading="detailUploading">
              <i class="el-icon-plus uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="市场价格">
          <div style="display: flex">
            <el-input
              style="width: 300px"
              v-model="marketPrice"
              oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="真实价格">
          <div style="display: flex">
            <el-input
              style="width: 300px"
              v-model="realPrice"
              oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
            ></el-input>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import FileUtil from '../../utils/FileUtil';
import SpuApi from '../../request/SpuApi';
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  data() {
    return {
      spuId: null,
      spu: {},
      logoUrl: '',
      sort: 0,
      salesCount: 0,
      bannerImageList: [],
      detailImageList: [],
      coverUploading: false,
      bannerUploading: false,
      detailUploading: false,
      qrImageUrlLoading: false,
      marketPrice: '',
      realPrice: '',
      qrImageUrl: '',
    };
  },
  watch: {
    bannerImageList(val) {
      console.log(val, 'val');
    },
  },
  mounted() {
    this.spuId = this.$route.query.id;
    if (this.spuId) {
      this.getSpuDetail();
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getSpuDetail() {
      SpuApi.detail({
        spuId: this.spuId,
      }).then((res) => {
        if (res.success) {
          this.spu = res.data;
          this.sort = res.data.sort;
          this.salesCount = res.data.salesCount;
          this.logoUrl = res.data.logoUrl;
          this.qrImageUrl = res.data.qrImageUrl;
          this.bannerImageList = JSON.parse(res.data.imagesUrl);
          let descJson = JSON.parse(res.data.descJson);
          descJson.forEach((element) => {
            if (element.type === 'image') {
              this.detailImageList.push(element);
            }
          });
          this.marketPrice = (res.data.skuList[0].marketPrice / 100).toString();
          this.realPrice = (res.data.skuList[0].realPrice / 100).toString();
        }
      });
    },
    uploadCover(file) {
      this.uploadImage('cover', file);
      return false;
    },
    uploadBanner(file) {
      this.uploadImage('banner', file);
      return false;
    },
    uploadDetail(file) {
      this.uploadImage('detail', file);
      return false;
    },
    uploadQrImage(file) {
      this.uploadImage('qrImageUrl', file);
      return false;
    },
    async uploadImage(type, file) {
      let fileType = 'image';
      if (file.type.indexOf('video') !== -1) {
        fileType = 'video';
      }
      if (type === 'cover') {
        this.coverUploading = true;
      } else if (type === 'banner') {
        this.bannerUploading = true;
      } else if (type === 'qrImageUrl') {
        this.qrImageUrlLoading = true;
      } else {
        this.detailUploading = true;
      }
      const result = await FileUtil.uploadFile(
        {
          file: file,
          dir: type,
          rename: true,
          suffix: fileType === 'video' ? 'mp4' : 'jpg',
        },
        this.$http
      );
      this.coverUploading = false;
      this.bannerUploading = false;
      this.detailUploading = false;
      if (result.success) {
        if (type === 'cover') {
          this.logoUrl = result.url;
        } else if (type === 'banner') {
          this.bannerImageList.push({ type: fileType, value: result.url });
        } else if (type === 'qrImageUrl') {
          this.qrImageUrl = result.url;
        } else {
          this.detailImageList.push({ type: 'image', value: result.url });
        }
      }
      return false;
    },
    handleRemoveImage(type, index) {
      if (type === 'cover') {
        this.logoUrl = '';
      } else if (type === 'banner') {
        this.bannerImageList.splice(index, 1);
      } else if (type === 'qrImageUrl') {
        this.qrImageUrl = '';
      } else {
        this.detailImageList.splice(index, 1);
      }
    },
    onSubmit() {
      if (!this.logoUrl.length) {
        this.$message.info('请上传封面图片');
        return;
      }
      if (!this.bannerImageList.length) {
        this.$message.info('请上传Banner图片');
        return;
      }
      if (!this.detailImageList.length) {
        this.$message.info('请上传商品详情图片');
        return;
      }
      if (!this.marketPrice.length || !this.realPrice.length) {
        this.$message.info('请输入商品价格');
        return;
      }
      if (this.spu.showPopup && !this.qrImageUrl) {
        this.$message.info('请上传弹窗内容');
        return;
      }
      this.spu.sort = +this.spu.sort;
      this.spu.logoUrl = this.logoUrl;
      this.spu.qrImageUrl = this.qrImageUrl;
      this.spu.playCount = +this.spu.playCount;
      this.spu.salesCount = +this.spu.salesCount;
      this.spu.imagesUrl = JSON.stringify(this.bannerImageList);
      this.spu.descJson = JSON.stringify(this.detailImageList);
      this.spu.type = 'COURSE_GOODS';
      this.spu.userId = this.$store.getters.getUserInfo.id;
      if (this.spuId) {
        this.spu.skuList[0].marketPrice = this.marketPrice * 100;
        this.spu.skuList[0].realPrice = this.realPrice * 100;
        this.spu.skuList[0].propertyId = this.spu.skuList[0].specPropertyId;
        this.spu.skuList[0].id = this.spu.skuList[0].skuId;
        this.spu.spuId = this.spuId;
        SpuApi.update(this.spu).then((res) => {
          if (res.success) {
            this.$message.success('更新成功');
            this.back();
          }
        });
      } else {
        this.spu.skuList = [
          {
            type: 'NORMAL',
            marketPrice: this.marketPrice * 100,
            realPrice: this.realPrice * 100,
            propertyId: 13,
            specId: 3,
          },
        ];
        SpuApi.insert(this.spu).then((res) => {
          if (res.success) {
            this.$message.success('添加成功');
            this.back();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  overflow: auto;
  top: 140px;
  width: calc(100vw - 200px - 40px);
  height: calc(100vh - 140px);
}
.uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 150px;
  height: 150px;
}
.uploader:hover {
  border-color: #409eff;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.uploader-icon:hover {
  color: #409eff;
}
.image-hover {
  position: absolute;
  width: 150px;
  height: 35px;
  bottom: 0;
  .delete {
    display: none;
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.3);
    .delete {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      color: white;
      font-size: 24px;
    }
  }
}
</style>
